import UnityViewer from './UnityViewer';
import decode from "jwt-decode";

function App() {
  let claims = {}
  try {
    const { searchParams } = new window.URL(window.document.location);
    const token = searchParams.get('token');
    claims = decode(token);
  }
  catch (e) {
    console.log(e)
  }
  
  if (!claims.siteId) return <div>You are not authorized to view this app</div>
  return (
    <div>
      <UnityViewer siteId={claims.siteId} />
    </div>
  );
}

export default App;
