import React, { useEffect, useState } from 'react';

function loadScript(src) {
  return new Promise(function (resolve, reject) {
      var s;
      s = document.createElement('script');
      s.src = src;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
  });
};

const siteMap = {
  'f8bd8391-673b-41a2-a965-ed9e724aad59': {
    url: 'https://storage.googleapis.com/your.vu.city/dev/advancedemo/52Lime',
    entry: '/Build/Advance.json',
  },
  'stripped52Lime': {
    url: 'https://storage.googleapis.com/your.vu.city/dev/advancedemo/Stripped52Lime',
    entry: '/Build/Advance.json',
  },
};

export default function UnityViewer({ siteId }) {
  const [unityInstance, setUnityInstance] = useState();
  useEffect(
    () => {
      const loadUnity = async () => {
        const site = siteMap[siteId];
        await loadScript(`${site.url}/Build/UnityLoader.js`)
        const instance = window.UnityLoader.instantiate(
          "unityContainer", 
          `${site.url}${site.entry}`,
          { onProgress: window.UnityProgress }
        )
        setUnityInstance(instance);
      };
      loadUnity();
    },
    [siteId],
  )
  return (
    <div className="webgl-content">
      <div id="unityContainer" style={{ width: '100%', height: 'calc(100vh - 43px)'}}></div>
      <div className="footer">
        <div className="webgl-logo"></div>
        <div className="fullscreen" onClick={() => unityInstance.SetFullscreen(1)}></div>
      </div>
    </div>
  )
}
